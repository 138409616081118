/* scss/organisms/_appModal.scss */

.appModal{
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;

  background: rgba(0, 0, 0, 0.84);
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
  padding: 15px;
  z-index: -1;
}

.appModal__content{
  position: absolute;
  bottom:60px;
  width: 100%;

}

.modal-is-open{
  .appModal{
    visibility: visible;
    opacity: 1;
    z-index: 800;
  }
}