/* scss/siteButtons/_btnStack.scss */

.btnStack{
  .btns{
    display: block;
    max-width: 301px;
    margin: auto;
    margin-bottom: $base/2;
    /*&:last-of-type{
      margin-bottom: 0;
    }*/
  }
}

