/* scss/siteForms/_label.scss */

.formLabel, label {
  font-weight: 400;
  margin-bottom: $base/4;
  font-family: $bodyFont;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 0;
}