/* scss/molecules/_appTimer.scss */

.appTimer {
  margin-bottom: $base;
}

.appTimer__input {
  display: inline-block;
  width: 100px;
  border: none;
  font-size: 73px;
  text-align: center;
  position: relative;
  padding: 0;
}


.appTimer__divider {
  display: inline-block;
  font-size: 50px;
  vertical-align: text-bottom;
  position: relative;
  top: 3px;

}

.appActions {
  margin-top: 50px;
  text-align: center;

  .btnStack{
    display: none;
  }


}