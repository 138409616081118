/* scss/molecules/_timeControl.scss */

.appFooter{
  .timeControl{
    position: absolute;
    bottom: 68px;
    right: 15px;
    z-index: 200;
    height: 53px;
    width: 53px;
    /*line-height: 55px;*/

    text-align: center;
    background: #2e47bb;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
  }
}


.timeControl{
  display: inline-block;
  /*img{
    max-width: 47px;
    cursor: pointer;
  }*/

  .fa, img{
    font-size: 22px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .fa-play{
    left: 2px;
  }
}


