/* scss/siteButtons/_btnMsg.scss */

.btnMsg{
  text-align: center;
  .btns{
    margin-bottom: $base/2;
  }
}
.btnMsg__msg{
  font-size: 14px;
  margin-bottom: 0;
  a{
    color: $textColor;
    text-decoration: underline;
  }
}