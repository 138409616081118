/* scss/molecules/_controlBox.scss */

.controlBoxRow {
  padding: 0 10px;
}

.controlBox {
  text-align: center;
  max-width: 105px;
  margin: auto;
}

.controlBox__title {
  margin-bottom: $base/4;
  font-size: 18px;
  line-height: 1;
}

.controlBox__content {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
}

.controlBox-alt {
  text-align: center;
  max-width: 200px;
  margin: auto;
  .controlBox__title {
    font-size: 16px;
  }

}