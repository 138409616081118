/* scss/molecules/_appLogo.scss */


.appLogoGroup{
  text-align: center;
}

.appLogo{
  font-weight: bold;
  font-size: 50px;
  color: $textColor;
  line-height: 1;
  &.appLogo-sml{
    font-size: 24px;
    span{
      color: #111111;
      font-weight: normal;
    }
  }
  span{
    color: $color1;
  }
}



.appLogo__sub{
  font-weight: bold;
  font-size: 16px;
}