/* scss/misc/_cleanup.scss */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
  font-family: $font2;
  color: $textColor;
  font-size: 18px;
}
img{
  max-width:100%;
}
hr{
  border:1px solid #ccc;
}

a:hover, a:focus{
  text-decoration: none;
}

.google-maps {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}


.source {
  max-width: 450px;
  height: 600px;
  margin: auto;
  border: 1px solid #ccc;
}

.u_vert{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

html, body, .l_h100{
  height: 100%;
}

body{
  max-width: 500px;
  margin: auto;
  background: #8f8f8f;
  position: relative;
}

.wrapper{

  position: relative;
}

.l_h80{
  height: 80%;
}

.l_h20{
  height: 20%;
}
.u_bgwhite{
  background-color: #fff;
}
.atoms-main{
  background: #fff;
}

.underlay{
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}


.imgFrame{
  border: 1px solid red;
}

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    -moz-appearance:textfield !important;
}
