/* scss/molecules/_appBar.scss */

.appBar{
  padding: 20px 20px;
  position: absolute;
  width: 100%;
  z-index: 500;
  .fa{
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
  a{
    color: $textColor;
  }
}

.wht-appBar{
  .appBar{
    .fa{
      color: #fff;
    }
  }
}

.navBox{
  list-style: none;
  padding: 10px 0;
  margin: 0;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all .25s;
  z-index: -1;
  box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.49);
  position: absolute;
  width: 177px;
  right: 0;
  a{
    display: block;
    padding: 10px 20px;
    color: $textColor;
    text-align: left;
  }
}

.nav-is-open{
  .navBox {
    visibility: visible;
    opacity: 1;
    z-index: 400;
  }
}