.btns {
  display: inline-block;
  background: $textColor;
  padding: 13px 60px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
  transition: all .25s;
  position: relative;
  border: none;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 15px;
  text-align: center;
  border-radius: 100px;
  width: 100%;
  max-width: 350px;
}

.btns:hover {
  color: #fff;
  text-decoration: none;
}

.btn1 {
  background: $color1;
  color: #fff;
  &:hover {
    background: $color1;
    color: #fff;
  }
}

.btn2 {
  background: #fff;
  color: $textColor;
  &:hover {
    background: #fff;
    color: $textColor;
  }
}

.btn3 {
  background: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 13px;
  font-weight: normal;
  font-size: 13px;
  &:hover {
    background: none;
    color: #fff;
    border: 1px solid #fff;
  }
}

.btn4 {
  background: #2e47bb;
  color: #fff;
  &:hover {
    background: #2e47bb;
    color: #fff;
  }
}

.simpleBtn {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.47);
  padding-bottom: 2px;
  font-weight: normal;
  &:hover {
    color: #fff;
  }
}

