/* scss/molecules/_appRow.scss */

.appRowGroup {
  padding-bottom: 15px;
}

.appRow {
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.47);
}



.appRow-simple {
  .appRow__content {
    top: 0;
    transform: translateY(0);
    p {
      line-height: 1.4;
    }
  }
  .appRow__item {
    height: auto !important;
    width: 100%;
  }
  .appRow__title {
    font-weight: normal;
  }
  .appRow__meta {
    font-size: 19px;
    font-weight: bold;
  }
}

.appRow__item{
  position: relative;
}

.appRow__item-img {
  @include col($two);
}

.appRow__item-content {
  @include col($six);
  padding-left: 10px;
}

.appRow__item-btns {
  @include col($four);
  text-align: right;
}



.appRow__imgFrame {

  height: 66px;
  width: 66px;
  border-radius: 50%;
  overflow: hidden;
}

.cssvert{
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}


.appRow__content {
  padding: 0 10px;
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  p {
    margin: 0;
    color: #fff;
    margin-bottom: 4px;
  }
}



.appRow__btns {
  display: inline-block;
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.appRow__meta {
  font-size: 14px;
  line-height: 1;
}

.appRow__title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}

.appRow__sub {
  font-size: 14px;
  line-height: 1;
  span {
    margin-left: 12px;
  }
}






