/* scss/organisms/_appBanner.scss */

.appBanner{
  padding: 40px 30px;
  padding-top: 107px;
  text-align: center;
  background-size: cover;
  p{
    color: $textColor;
  }
}

.appBanner__heading{
  font-size: 36px;
  margin-bottom: $base/2;
  color: $textColor;
}
