/* scss/molecules/_userBox.scss */

.userBox{
  text-align: center;
  padding: 102px 0;
  padding-bottom: 50px;
  a{
    background: #111;
    color: #fff;
    border-radius: 116px;
    padding: 5px 14px;
    font-size: 14px;
  }
}

.userBox__img{
  width: 175px;
  height: 175px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: $base/2;
}

.userBox__name{
  margin-bottom: $base/2;
  color: $textColor;
  font-weight: 400;
}