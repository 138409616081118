.appToast {
  background: #fff;
  padding: 20px;
  position: fixed;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  -webkit-transition: all .5s;
  transition: all .5s;
  max-width: 500px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  text-align: center;
  font-size: 18px;
  color: #000;
}

.appToast-bottom-center{
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -100%;
}
.appToast-bottom-center.appToast-active{
  bottom:0;
}

.appToast-top-center{
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -100%;
}
.appToast-top-center.appToast-active{
  top:0;
}


.appToast-top-left{
  left: 20px;
  top: -100%;
}
.appToast-top-left.appToast-active{
  top:0;
}

.appToast-top-right{
  right: 20px;
  top: -100%;
}
.appToast-top-right.appToast-active{
  top:0;
}




.appToast-bottom-right{
  right: 20px;
  bottom: -100%;
}
.appToast-bottom-right.appToast-active{
  bottom:0;
}

.appToast-bottom-left{
  left: 20px;
  bottom: -100%;
}
.appToast-bottom-left.appToast-active{
  bottom:0;
}








.appToast p {
  margin-bottom: 0;
}

.appToast .fa {
  font-size: 48px;
  display: inline-block;
}

.appToast-success {
  color: #61B357;
}

.appToast-warning {
  color: #FFBC20;
}

.appToast-danger {
  color: #EF4F4F;
}

.trigger{
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  padding: 15px;
  color:#fff;
  font-weight: bold;
  cursor:pointer;
  background: #3C4F6D;
  color: #fff;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  display: inline-block;
}