/* scss/molecules/_appHeadGroup.scss */

.appHeadGroup{
  text-align: center;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.47);
  padding-bottom: 15px;
}

.appHeadGroup__head{
  color: #fff;
  margin-bottom: $base/3;
  font-size: 26px;
}

.appHeadGroup__sub{
  margin-bottom: 0;
}

.appHeadGroup-alt{
  border-bottom: none;
}