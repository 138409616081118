.datetimepicker {
  padding: 10px;
  left: 0 !important;
  .today {
    cursor: pointer;
    text-align: center;

  }
  .day {
    cursor: pointer;
    text-align: center;
    background: #eee;
    border: 1px solid #fff;
    &.active {
      background: #2e47bb;
      color: #fff;
    }
  }
  .switch {
    text-align: center;
  }
}

