/* scss/molecules/_minBlock.scss */

.minBlock{
  text-align: center;
  text-align: center;
  padding: 40px 0;
  padding-top: 107px;
}

.minBlock__min{
  color: $textColor;
  margin-bottom: 0;
  font-size: 113px;
  line-height: 1;
}

.minBlock-alt{
  .minBlock__min{
    font-size: 50px;
  }
}

.minBlock__controls{
  button{
    border: none;
    background: none;
    padding: 0;
  }
  .fa{
    color: $textColor;
    font-size: 45px;
    margin: 0 20px;
  }
}

