/* scss/siteForms/_form-input.scss */

.form-input {
  height: 40px;
  border-radius: 0;
  padding-left: 10px;
  width: 100%;
  background: none;
  line-height: 40px;
  border: none;
  border-bottom: 2px solid #fff;
  padding-top: 0;
  transition: all 0.1s linear;
  color: #fff;
  font-weight: normal;
  &:focus {
    outline: 0;
  }
}

select.form-input{
  background: transparent;

}


select.form-input option {
  background: $color1;
}

/*select.form-input option[value="1"] { !* value not val *!
  background: red;
}*/




textarea.form-input{
  border: 2px solid;
  height: 200px;
}