/* scss/layout/_appScreen.scss */

.appScreen{
  //max-width: 400px;

  height: 100%;
  position: relative;

  .appLogoGroup{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.appContent{
  padding: 30px 15px;
  padding-bottom: 121px;
}

.appForm{
  .btnGroup{
    padding-top: $base*2;
  }
}

.appFooter-alt{
  position: absolute;
  bottom: 30px;
  width: 100%;
}

