/*scss/variables/_colors.scss*/

$color1: #fc4c1a; //PRIMARY COLOR
$color2: #00b19f;  //ACCENT COLOR
$color3: #eff1f1;

$gradient: linear-gradient(45deg, #fc4a1a 36%,#ffd200 100%);



$textColor: #111111;  //BODY TEXT COLOR

.color1{
	background: $color1;
}
.color2{
	background: $color2;
}
.color3{
	background: $color3;
}
.textColor{
	background: $textColor;
}
.gradient{
	background: $gradient;
}



.colors{
	@extend .grid-row;
}
.color{
    width:100px;
    height:100px;
    float:left;
    margin-right:10px;
    padding:10px;
    color:#fff;
    font-size:12px;
}

.colorFixed{
  position: fixed;
  z-index: 3000;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.57);
  .color{
    float: none;
    margin-right:0;
    width:70px;
    height:50px;
  }
}

