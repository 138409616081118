.titleBox{
  padding: 90px 15px;
  padding-bottom: $base;
  text-align: center;
  .fa{
    font-size: 80px;
    margin-bottom: $base;
  }
}

.titleBox__link{
  background: #111;
  color: #fff;
  border-radius: 116px;
  padding: 5px 14px;
  font-size: 14px;
  &:hover{
    color: #fff;
  }
}

.titleBox__name{
  margin-bottom: $base/2;
  color: $textColor;
  font-size: 40px;
}

.titleBox__source{
  font-weight: bold;
  color: #fc4a1a;
}

.titleBox__content{
  margin-bottom: $base/2;
}