.h1, h1, h2, .h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6 {
  margin-top: 0;
  margin-bottom: $base;
  font-family: $bodyFont;
  line-height: 1;
}

.h1, h1 {
  font-size: 40px;
  color: #fff;
  text-align: center;
}

.h2, h2 {
  font-size: 30px;
  color: $color2;
}

.h3, h3 {
  font-size: 30px;
  font-weight: 400;
  color: $color2;
}

.h4, h4 {
  font-size: 20px;
  color: $color3;
}
