/* scss/siteFonts/_fonts.scss */

$bodyFont: 'Work Sans', sans-serif;
$font2: 'Work Sans', sans-serif;

.font1{
	font-family: $bodyFont;
}
.font2{
	font-family: $font2;
	font-weight: 300;
}

.font{
    font-weight: 400;
	span{
		display:block;
	}
}