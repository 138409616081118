/* scss/organisms/_appHeader.scss */


.appHeader{
  padding: 40px 0;
  padding-bottom: 20px;
  text-align: center;
}

.appHeader__heading{
  margin-bottom: 0;
}

.appHeader-alt{
  padding-top: 80px;
  .appHeader__heading{
    color: $textColor;
    font-size: 30px;
  }
  .fa{
    font-size: 80px;
    margin-bottom: $base;
  }
}