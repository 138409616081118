/* scss/organisms/_appFooter.scss */



.appFooter{
  width: 100%;
  position: fixed;
  bottom: 0;
  max-width: 500px;
  margin: auto;
}

.appFooterNav{
  list-style: none;
  padding: 0;
  margin: 0;
  display: table;
  width: 100%;
  li{
    display: table-cell;
    &:first-child{
      a{
        margin-right: 4px;
      }
    }
    &:last-child{
      a{
        margin-left: 4px;
      }
    }
  }
  a{
    display: block;
    background: #fff;
    color: $textColor;
    text-align: center;
    font-weight: bold;
    //text-transform: uppercase;
    padding: 12px 0;
    font-size: 19px;
  }
}

.appFooter__active{
  a{
    text-decoration: none;
    background: $textColor;
    color: #fff;
  }
}


.appSimpleFooter{
  padding: 40px 0;
  text-align: center;
}